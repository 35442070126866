<template>
    <div class="page">
        <div class="content">
            <div class="top">
                <Button type="primary" style="background: #296FE1;margin-right: 20px;" @click="allRead">全部已读</Button>
                <Button @click="goPhrase">快捷短语</Button>
            </div>
            <div style="min-height: 70vh;">
                <Table :columns="columnsTable" :data="dataTable" border>
                    <template slot-scope="{ index }" slot="id">
                        <div>{{ index + 1 }}</div>
                    </template>
                    <template slot-scope="{ row }" slot="status">
                        <div v-if="row.status == 0">未读</div>
                        <div v-if="row.status == 1">已读</div>
                        <div v-if="row.status == 2">回复消息</div>
                    </template>
                    <template slot-scope="{ row }" slot="manage">
                        <div style="display: flex;flex-direction: row;align-content: center;justify-content: center;">
                            <Button type="primary" ghost @click="goReply(row)" label="small">回复</Button>
                        </div>
                    </template>
                </Table>
            </div>
            <Page class="Page" :current="storePrams.page" :page-size="storePrams.limit" @on-change="pageChange" :total="total" show-elevator show-total />
        </div>
         <!-- 回复 -->
         <Modal v-model="replyModal" title="回复" width="60" :mask-closable="false">
            <div style="margin-bottom: 100px;">
                <div style="margin: 10px 0;" v-for="(item,index) in boxList" :key="index">
                    <div v-if="item.type == 1" class="box-left">
                        <div class="h">{{item.user.name}}</div>
                        <div class="txt-left">{{item.message}}</div>
                    </div>
                    <div v-if="item.type == 2" class="box-right">
                        <div></div>
                        <div style="display: flex;flex-direction: row;">
                            <div class="txt-right">{{item.reply}}</div>
                            <div class="h">{{item.business.name}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <div style="width: 100%;display: flex;flex-direction: row;align-items: center;justify-content: space-between;">
                    <Input ref="input" type="text" v-model="messageValue" placeholder="" style="width: 70%;" :border="false"></Input>
                    <div style="width: 180px;display: flex;flex-direction: row;align-items: center;">
                        <div style="text-align: left;">
                            <Poptip width="270" placement="top-end" v-model="poptipValue">
                                <Button icon="ios-list-box-outline" @click="goCommontell">常用语</Button>
                                <!-- slot - content -->
                                <div slot="content">
                                    <div class="boxs">
                                        <div style="max-height: 100px;overflow: auto;">
                                            <div class="box" v-for="(item, index) in commontellList" :key="index" >
                                                <div class="box-txt" @click="goMessage(item)">{{item.message}}</div>
                                                <img class="box-icon" src="@/assets/del_icon.png" @click="delList(item)">
                                            </div>
                                        </div>
                                        <div class="box-foot" @click="addCommontell"> + 添加常用语</div>
                                    </div>
                                </div>
                            </Poptip>
                        </div>
                        <Button type="primary" style="background: #296FE1;margin-left: 20px;" @click="sending">发送</Button>
                    </div>
                </div>
            </div>
        </Modal>
        <!-- 创建快捷回复 -->
        <Modal v-model="answerModal" title="创建快捷回复" :mask-closable="false">
            <div style="display: flex;justify-content: center;height: 100px;align-items: center">
                <div>
                    <Input v-model="inputValue" maxlength="100" type="textarea" placeholder="请输入创建快捷回复" style="width: 350px;" />
                </div>
            </div>
            <div slot="footer">
                <Button @click="cancelQModal">取消</Button>
                <Button type="primary" style="background: #296FE1;" @click="saveQModal">保存</Button>
            </div>
        </Modal>
        <!-- 快捷短语 -->
        <Modal v-model="phraseModal" title="快捷短语" :mask-closable="false" width="50">
            <div>
                <Button type="primary" style="background: #296FE1;margin-bottom: 20px;" @click="addphraseItem">添加短语</Button>
                <Table :columns="columnsModalTable" :data="dataModalTable" border>
                    <template slot-scope="{ index }" slot="id">
                        <div>{{ index + 1 }}</div>
                    </template>
                    <template slot-scope="{ row }" slot="manage">
                        <div style="display: flex;flex-direction: row;align-content: center;justify-content: space-around;">
                            <div class="table-btn" style="border: 1px solid #FF0000;color: #FF0000;" @click="delphraseItem(row)">删除</div>
                        </div>
                    </template>
                </Table>
            </div>
            <div slot="footer"></div>
        </Modal>
    </div>
</template>

<script>
import { messageList, message_user_list, messageReply, message_create_phrases, message_phrases_list, message_del_phrases, message_all_message_use } from "@/api/index";
    export default {
        data() {
            return {
                total: 0,
                storePrams: {
                    page: 1,
                    limit: 30
                },
                columnsTable: [
                    {title: '#',slot: "id",align:'center',width:'130px'},
                    {title: '用户名',key: 'user_name',align:'center'},
                    {title: '信息内容',key: 'message',align:'center'},
                    {title: '时间',key: 'created_at',align:'center'},
                    {title: '状态',slot: 'status',align:'center'},
                    {title: '操作',slot: 'manage',align:'center'}
                ],
                dataTable: [],
                columnsModalTable: [
                    {title: '#',slot: "id",align:'center',width:'130px'},
                    {title: '内容',key: 'message',align:'center'},
                    {title: '操作',slot: 'manage',align:'center',width:'200px'}
                ],
                dataModalTable: [],
                replyModal: false,
                boxList: [],
                messageValue: '',
                commontellList: [],
                answerModal: false,
                inputValue: '',
                poptipValue: false,
                id: '',
                user_id: '',
                phraseModal: false
            }
        },
        created(){},
        mounted(){
            this.$refs.input.focus({ cursor: 'start' })
            this.messageList()
            this.message_phrases_list()
        },
        methods: {
            goPhrase(){
                this.phraseModal = true
            },
            delphraseItem(e){
                message_del_phrases({id: e.id}).then(res => {
                    this.message_phrases_list()
                    this.$Message.success(res.msg);
                }).catch((res) => {
                    this.$Message.error(res.msg);
                });
            },
            addphraseItem(){
                this.answerModal = true
            },
            message_phrases_list(){
                message_phrases_list().then(res => {
                    this.commontellList = res.data
                    this.dataModalTable = res.data
                }).catch((res) => {
                    this.$Message.error(res.msg);
                });
            },
            messageList(){
                messageList(this.storePrams).then(res => {
                    this.dataTable = res.data.list
                    this.total = res.data.count[0].total
                }).catch((res) => {
                    this.$Message.error(res.msg);
                });
            },
            pageChange(index) {
                this.storePrams.page = index;
                this.messageList()
            },
            goReply(e){
                this.id = e.id
                this.user_id = e.user_id
                this.replyModal = true
                setTimeout(() => {
                    //500毫秒后执行代码
                    this.$refs.input.focus({ cursor: 'start' })
                }, 500);
                this.message_user_list()
            },
            message_user_list(){
                message_user_list({user_id: this.user_id}).then(res => {
                    this.boxList = res.data
                }).catch((res) => {
                    this.$Message.error(res.msg);
                });
            },
            sending(){
                if(!this.messageValue){
                    this.$Message.error('请输入发送内容');
                    return
                }
                let data = {
                    id: this.id,
                    message: this.messageValue
                }
                messageReply(data).then(res => {
                    this.message_user_list()
                    this.$Message.success(res.msg);
                }).catch((res) => {
                    this.$Message.error(res.msg);
                });
                this.messageValue = ''
            },
            // 常用语
            goCommontell(){

            },
            addCommontell(){
                this.answerModal = true
            },
            cancelQModal(){
                this.answerModal = false
            },
            saveQModal(){
                this.answerModal = false
                message_create_phrases({message: this.inputValue}).then(res => {
                    this.message_phrases_list()
                    this.inputValue = ''
                    this.$Message.success(res.msg);
                }).catch((res) => {
                    this.$Message.error(res.msg);
                });
            },
            goMessage(e){
                this.messageValue = e.message
                this.poptipValue = false
            },
            delList(e){
                message_del_phrases({id: e.id}).then(res => {
                    this.message_phrases_list()
                    this.$Message.success(res.msg);
                }).catch((res) => {
                    this.$Message.error(res.msg);
                });
            },
            // 全部已读
            allRead(){
                message_all_message_use().then(res => {
                    this.messageList()
                    this.$Message.success(res.msg);
                }).catch((res) => {
                    this.$Message.error(res.msg);
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
.page{
    width: 100%;
    height: 100%;
    background-color: #E9F0F2;
    padding: 20px;
}
.content{
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
}
.top{
    display: flex;
    flex-direction: row;
    align-content: center;
    margin-bottom: 20px;
}
.table-btn{
    width: 48px;
    height: 24px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #296FE1;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #296FE1;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
}
.Page{
    margin-top: 20px;
    text-align: right;
}
.box-left{
    display: flex;
    flex-direction: row;
}
.box-right{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.h{
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
}
.txt-left{
    margin-left: 10px;
    background: #D4E2F9;
    border-radius: 0px 18px 18px 18px;
    padding: 10px;
}
.txt-right{
    margin-right: 10px;
    background: #D4E2F9;
    border-radius: 18px 0px 18px 18px;
    padding: 10px;
}
.box{
    width: 100%;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    border-bottom: 1px solid #C6D1DB;
    padding: 5px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.box-txt{
    width: 13em;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    cursor: pointer;
}
.box-icon{
    width: 14px;
    height: 14px;
    cursor: pointer;
}
.boxs .box:first-child{
    padding: 0px 0px 5px 0px !important;
}
.box-foot{
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #296FE1;
    padding-top: 5px;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
}
::v-deep .ivu-poptip-inner {
    background: #F7F7F7;
    border-radius: 10px;
    border: 1px solid #C6D1DB;
    box-shadow: none;
}
//表头背景颜色
::v-deep .ivu-table-header thead tr th {
    background: #F5F9FA;
}
// table中所有的边框 
::v-deep .ivu-table td{
    border-left: none;
    border-right: none;
}
//表头字体颜色
::v-deep .ivu-table-wrapper .ivu-table .ivu-table-header tr th {
    color: #666666 !important;
}
</style>